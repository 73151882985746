import React from "react"
import { graphql } from "gatsby"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import innertext from "innertext"
const BlogpostLayout = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <div>
      <SEO
        title={innertext(post.frontmatter.title)}
        //description={innertext(post.excerpt)}
        //image={post.featured_media.source_url}
        //keywords={post.categories.map(res => res.name).join(", ")}
      />
      <Header />
      <main>
        <div className="container">
          <div className="row justify-content-md-center">
            <h1 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default BlogpostLayout

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: { eq: $slug }}){
      html
      frontmatter { 
        title
      }
    }
  }
`
